import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d1c5619 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _29105e19 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _75570e38 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _e59e05d2 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _a761df4a = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _86a4c6b0 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _46634c16 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _5425d712 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _63438f54 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _07384d3c = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _bfc73890 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _715c6e46 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _12496bf2 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _0d8a0cf9 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _5a2619ce = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _2a36a68e = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _2192d2d1 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _1649310e = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _337e5a59 = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _2ffd5d00 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _783406b3 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _336e122a = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _367e0c9b = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt.html",
    component: _0d1c5619,
    name: "blog___b2c_nb_no"
  }, {
    path: "/checkout",
    component: _29105e19,
    name: "checkout___b2c_nb_no",
    children: [{
      path: "bestilling",
      component: _75570e38,
      name: "checkout-order___b2c_nb_no"
    }, {
      path: "konto",
      component: _e59e05d2,
      name: "checkout-account___b2c_nb_no"
    }, {
      path: "takk",
      component: _a761df4a,
      name: "checkout-thank-you___b2c_nb_no"
    }]
  }, {
    path: "/customer",
    component: _86a4c6b0,
    meta: {"titleLabel":"Account"},
    name: "customer___b2c_nb_no",
    children: [{
      path: "addresses-details",
      component: _46634c16,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___b2c_nb_no"
    }, {
      path: "my-profile",
      component: _5425d712,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___b2c_nb_no"
    }, {
      path: "order-history",
      component: _63438f54,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___b2c_nb_no"
    }, {
      path: "addresses-details/create",
      component: _07384d3c,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___b2c_nb_no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _bfc73890,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___b2c_nb_no"
    }, {
      path: "order-history/:orderId",
      component: _715c6e46,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___b2c_nb_no"
    }]
  }, {
    path: "/faq.html",
    component: _12496bf2,
    name: "faq___b2c_nb_no"
  }, {
    path: "/onskeliste",
    component: _0d8a0cf9,
    name: "wishlist___b2c_nb_no"
  }, {
    path: "/search",
    component: _5a2619ce,
    name: "search___b2c_nb_no"
  }, {
    path: "/sveacheckout",
    component: _2a36a68e,
    name: "sveaCheckout___b2c_nb_no"
  }, {
    path: "/customer/reset-password",
    component: _2192d2d1,
    alias: "/customer/account/createPassword",
    name: "reset-password___b2c_nb_no"
  }, {
    path: "/sveacheckout/confirmation",
    component: _1649310e,
    name: "sveaCheckoutConfirmation___b2c_nb_no"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _337e5a59,
    name: "blogPost___b2c_nb_no"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _2ffd5d00,
    name: "attributeCollection___b2c_nb_no"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _783406b3,
    name: "attributeCollectionItem___b2c_nb_no"
  }, {
    path: "/",
    component: _336e122a,
    name: "home___b2c_nb_no"
  }, {
    path: "/:slug+",
    component: _367e0c9b,
    name: "page___b2c_nb_no"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
