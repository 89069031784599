import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_3510bec3 from 'nuxt_plugin_plugin_3510bec3' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_wmIcons_7b4d6cfa from 'nuxt_plugin_wmIcons_7b4d6cfa' // Source: ./wmIcons.js (mode: 'all')
import nuxt_plugin_nuxtplugin2afa25c4_263cf1b9 from 'nuxt_plugin_nuxtplugin2afa25c4_263cf1b9' // Source: ./nuxt.plugin.2afa25c4.js (mode: 'server')
import nuxt_plugin_image_f63482a8 from 'nuxt_plugin_image_f63482a8' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_68a8df59 from 'nuxt_plugin_cookieuniversalnuxt_68a8df59' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_9401b6ac from 'nuxt_plugin_pluginutils_9401b6ac' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_20978b04 from 'nuxt_plugin_pluginseo_20978b04' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_2e6da116 from 'nuxt_plugin_pluginrouting_2e6da116' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7b3c0fcc from 'nuxt_plugin_pluginmain_7b3c0fcc' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_runtimepluginc04389bc_2dbc0ecb from 'nuxt_plugin_runtimepluginc04389bc_2dbc0ecb' // Source: ./runtime.plugin.c04389bc.mjs (mode: 'all')
import nuxt_plugin_customerplugin_6c341878 from 'nuxt_plugin_customerplugin_6c341878' // Source: ./customer.plugin.js (mode: 'all')
import nuxt_plugin_runtimeplugindd906bd6_26909f32 from 'nuxt_plugin_runtimeplugindd906bd6_26909f32' // Source: ./runtime.plugin.dd906bd6.mjs (mode: 'all')
import nuxt_plugin_portalvue_48be9142 from 'nuxt_plugin_portalvue_48be9142' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_dynamicContactForm_3dfe9ea2 from 'nuxt_plugin_dynamicContactForm_3dfe9ea2' // Source: ./dynamicContactForm.js (mode: 'all')
import nuxt_plugin_paymentSveaPlugin_35c641ef from 'nuxt_plugin_paymentSveaPlugin_35c641ef' // Source: ./paymentSveaPlugin.js (mode: 'all')
import nuxt_plugin_postscribeclient_fbe92610 from 'nuxt_plugin_postscribeclient_fbe92610' // Source: ./postscribe.client.js (mode: 'client')
import nuxt_plugin_runtimeplugin87457074_e481d8da from 'nuxt_plugin_runtimeplugin87457074_e481d8da' // Source: ./runtime.plugin.87457074.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin8d97859e_1e671930 from 'nuxt_plugin_runtimeplugin8d97859e_1e671930' // Source: ./runtime.plugin.8d97859e.mjs (mode: 'all')
import nuxt_plugin_runtimepluginad3739b6_a09547c4 from 'nuxt_plugin_runtimepluginad3739b6_a09547c4' // Source: ./runtime.plugin.ad3739b6.mjs (mode: 'all')
import nuxt_plugin_distplugin85aa0632_da262566 from 'nuxt_plugin_distplugin85aa0632_da262566' // Source: ./dist.plugin.85aa0632.mjs (mode: 'all')
import nuxt_plugin_pluginsfc12d4d202_7e65ecdc from 'nuxt_plugin_pluginsfc12d4d202_7e65ecdc' // Source: ./plugins.fc.12d4d202.mjs (mode: 'all')
import nuxt_plugin_coreplugin73dd73f8_08890ea7 from 'nuxt_plugin_coreplugin73dd73f8_08890ea7' // Source: ./core.plugin.73dd73f8.ts (mode: 'all')
import nuxt_plugin_pluginstokenexpired7e3254a1_72f39596 from 'nuxt_plugin_pluginstokenexpired7e3254a1_72f39596' // Source: ./plugins.token-expired.7e3254a1.ts (mode: 'all')
import nuxt_plugin_pluginsdompurify7d2c89e1_5cc66547 from 'nuxt_plugin_pluginsdompurify7d2c89e1_5cc66547' // Source: ./plugins.dompurify.7d2c89e1.ts (mode: 'all')
import nuxt_plugin_pluginsi18n39c58c4a_591804aa from 'nuxt_plugin_pluginsi18n39c58c4a_591804aa' // Source: ./plugins.i18n.39c58c4a.ts (mode: 'all')
import nuxt_plugin_pluginsfixLocaleRoutes40bcbab9_7172d1c7 from 'nuxt_plugin_pluginsfixLocaleRoutes40bcbab9_7172d1c7' // Source: ./plugins.fixLocaleRoutes.40bcbab9.ts (mode: 'all')
import nuxt_plugin_pluginse2etestingd0d90038_13e925e0 from 'nuxt_plugin_pluginse2etestingd0d90038_13e925e0' // Source: ./plugins.e2e-testing.d0d90038.js (mode: 'all')
import nuxt_plugin_deviceplugin_0b76183c from 'nuxt_plugin_deviceplugin_0b76183c' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_1f058e8d from 'nuxt_plugin_workbox_1f058e8d' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_aabc0ec0 from 'nuxt_plugin_metaplugin_aabc0ec0' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_a16cc3d8 from 'nuxt_plugin_iconplugin_a16cc3d8' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_storeConfigPlugin_cbd09bc6 from 'nuxt_plugin_storeConfigPlugin_cbd09bc6' // Source: ../node_modules/@wemade-vsf/core/lib/runtime/plugins/storeConfigPlugin.mjs (mode: 'server')
import nuxt_plugin_meta_b2e122d6 from 'nuxt_plugin_meta_b2e122d6' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"We Made","titleTemplate":"%s - We Made","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"og:site_name","property":"og:site_name","name":"og:site_name","content":"We Made"},{"hid":"og:title","name":"og:title","property":"og:title","content":"","template":(chunk) => chunk ? `${chunk} - We Made` : 'We Made'},{"hid":"og:description","property":"og:description","content":""},{"hid":"apple-mobile-web-app-title","property":"apple-mobile-web-app-title","content":"We Made"},{"name":"og:image","hid":"og:image","property":"og:image","content":"\u002Fogimage.jpg"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"bodyAttrs":{"id":"html-body"},"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_3510bec3 === 'function') {
    await nuxt_plugin_plugin_3510bec3(app.context, inject)
  }

  if (typeof nuxt_plugin_wmIcons_7b4d6cfa === 'function') {
    await nuxt_plugin_wmIcons_7b4d6cfa(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin2afa25c4_263cf1b9 === 'function') {
    await nuxt_plugin_nuxtplugin2afa25c4_263cf1b9(app.context, inject)
  }

  if (typeof nuxt_plugin_image_f63482a8 === 'function') {
    await nuxt_plugin_image_f63482a8(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_68a8df59 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_68a8df59(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_9401b6ac === 'function') {
    await nuxt_plugin_pluginutils_9401b6ac(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_20978b04 === 'function') {
    await nuxt_plugin_pluginseo_20978b04(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2e6da116 === 'function') {
    await nuxt_plugin_pluginrouting_2e6da116(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7b3c0fcc === 'function') {
    await nuxt_plugin_pluginmain_7b3c0fcc(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimepluginc04389bc_2dbc0ecb === 'function') {
    await nuxt_plugin_runtimepluginc04389bc_2dbc0ecb(app.context, inject)
  }

  if (typeof nuxt_plugin_customerplugin_6c341878 === 'function') {
    await nuxt_plugin_customerplugin_6c341878(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugindd906bd6_26909f32 === 'function') {
    await nuxt_plugin_runtimeplugindd906bd6_26909f32(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_48be9142 === 'function') {
    await nuxt_plugin_portalvue_48be9142(app.context, inject)
  }

  if (typeof nuxt_plugin_dynamicContactForm_3dfe9ea2 === 'function') {
    await nuxt_plugin_dynamicContactForm_3dfe9ea2(app.context, inject)
  }

  if (typeof nuxt_plugin_paymentSveaPlugin_35c641ef === 'function') {
    await nuxt_plugin_paymentSveaPlugin_35c641ef(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_postscribeclient_fbe92610 === 'function') {
    await nuxt_plugin_postscribeclient_fbe92610(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin87457074_e481d8da === 'function') {
    await nuxt_plugin_runtimeplugin87457074_e481d8da(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin8d97859e_1e671930 === 'function') {
    await nuxt_plugin_runtimeplugin8d97859e_1e671930(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimepluginad3739b6_a09547c4 === 'function') {
    await nuxt_plugin_runtimepluginad3739b6_a09547c4(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin85aa0632_da262566 === 'function') {
    await nuxt_plugin_distplugin85aa0632_da262566(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfc12d4d202_7e65ecdc === 'function') {
    await nuxt_plugin_pluginsfc12d4d202_7e65ecdc(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin73dd73f8_08890ea7 === 'function') {
    await nuxt_plugin_coreplugin73dd73f8_08890ea7(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokenexpired7e3254a1_72f39596 === 'function') {
    await nuxt_plugin_pluginstokenexpired7e3254a1_72f39596(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdompurify7d2c89e1_5cc66547 === 'function') {
    await nuxt_plugin_pluginsdompurify7d2c89e1_5cc66547(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n39c58c4a_591804aa === 'function') {
    await nuxt_plugin_pluginsi18n39c58c4a_591804aa(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfixLocaleRoutes40bcbab9_7172d1c7 === 'function') {
    await nuxt_plugin_pluginsfixLocaleRoutes40bcbab9_7172d1c7(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etestingd0d90038_13e925e0 === 'function') {
    await nuxt_plugin_pluginse2etestingd0d90038_13e925e0(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_0b76183c === 'function') {
    await nuxt_plugin_deviceplugin_0b76183c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_1f058e8d === 'function') {
    await nuxt_plugin_workbox_1f058e8d(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_aabc0ec0 === 'function') {
    await nuxt_plugin_metaplugin_aabc0ec0(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_a16cc3d8 === 'function') {
    await nuxt_plugin_iconplugin_a16cc3d8(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storeConfigPlugin_cbd09bc6 === 'function') {
    await nuxt_plugin_storeConfigPlugin_cbd09bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_b2e122d6 === 'function') {
    await nuxt_plugin_meta_b2e122d6(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
